import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Container from "@material-ui/core/Container"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Container style={{ paddingTop: 200 }}>
      <h1>NO ENCONTRADO</h1>
      <p>Esta página no existe, por favor vuelva a la página principal</p>
    </Container>
  </Layout>
)

export default NotFoundPage
